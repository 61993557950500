
import config from '../config'

const defLanguage = {
    lang: config.defaultLang
}

export default (state = defLanguage, action) => {
    switch (action.type) {
        case 'SET_LANGUAGE':
            if (action.lang) {
                return { lang: action.lang }
            } else {
                return defLanguage
            }
        default:
            return state
    }
}