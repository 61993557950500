import * as React from 'react'

import { connect } from 'react-redux'
import act from '../actions'
import GameComponent from '../components/Game'

class Game extends React.Component {
    render () {
        const { actions, state } = this.props
        return (
            <GameComponent
                actions={actions}
                state={state}
            />
        )
    }
}


const mapStateToProps = state => ({
    state,
})

const mapDispatchToProps = dispatch => ({
    actions: {
        setLanguage: (lang) => dispatch(act.setLanguage(lang)),
        newGame: (playerCount) => dispatch(act.newGame(playerCount)),
        stopGame: () => dispatch(act.stopGame()),
        backMenu: () => dispatch(act.backMenu()),
        clockTick: () => dispatch(act.clockTick()),
        flipCard: (id) => dispatch(act.flipCard(id)),
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(Game)