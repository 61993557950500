


export const translate = (lang) => (text) => {
    const map = require(`../translations/${lang}.json`)
    if (map[text]) {
        return map[text]
    } else {
        return text
    }
} 