
import * as React from 'react'

import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux'
import memoryGame from '../reducers'

import Game from './Game'
import thunk from 'redux-thunk';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

let store = createStore(
    memoryGame,
    {},
    composeEnhancers(
        applyMiddleware(thunk),
    ),
)

export default () => (
    <Provider store={store}>
        <Game />
    </Provider>
)