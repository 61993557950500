
import shuffle from 'knuth-shuffle-seeded'

import config from '../config'

/*
    possible values
    - menu
    - playing
    - won
*/

const defGameState = {
    type: 'menu',
}

const complete = (card) => ({
    id: card.id,
    groupId: card.group,
    imageUp: card.image,
    imageDown: config.cardBackground,
    side: 'down',
    matched: false,
})
const defaultFinalCards = [
    ...config.defaultCards.map(complete)
]


const newGame = (playerCount, seed) => {
    let playerInfo = []
    const cards = shuffle([...defaultFinalCards], String(1734) + String(seed))
    for (let i = 0; i < playerCount; i++) {
        playerInfo.push({
            checkCardsOn: false,
            totalmoves: 0,
            moves: 0,
            points: 0,
            timer: 0,            
        })
    }
    return {
        type: 'playing',
        currentPlayer: 0,
        generalTimer: 0,
        playerInfo,
        cards,
    }
}

const flipCardPlayerInfo = (playerInfo, cards, cardId) => {
    // we are still "waiting" for the cards to disappear
    if (playerInfo.checkCardsOn) {
        return [playerInfo, cards]
    } else {
        let upCards = []
        for (const card of cards) {
            if (card.side === 'up' && !(card.matched === true)) {
                upCards.push(card.id)
            }
        }
        const newCards = cards.map((c) => (
            c.id === cardId ? { ...c, side: 'up' } : c
        ))
        
        // a "move" is when two cards flipped as this represents one try
        const checkTwoMoves = (playerInfo.totalmoves % 2) === 0
        const newState = { 
            ...playerInfo, 
            totalmoves: playerInfo.totalmoves+1, 
            moves: checkTwoMoves ? playerInfo.moves+1 : playerInfo.moves
        }

        /* if we already had cards up, that means we need to set a timeout */
        if (upCards.length === 1) {
            // 2 represents the number of clock ticks to check the cards (e.g. flip cards back)
            return [{ ...newState, checkCardsOn: playerInfo.timer + 2 }, newCards]
        } else {
            return [newState, newCards]
        }
    }
}


const flipCard = (state, cardId) => {
    const _st = checkTimers(state)
    const playerInfo = [..._st.playerInfo]
    const [ pI, cs ] = flipCardPlayerInfo(playerInfo[state.currentPlayer], _st.cards, cardId)
    playerInfo[state.currentPlayer] = pI
    return { ..._st, playerInfo, cards: cs }
}

const removeGreenTags = (state) => ({
    ...state,
    cards: state.cards.map((c) => ({
        ...c,
        lastMatched: c.lastMatched && c.lastMatched >= state.generalTimer ? c.lastMatched : false,
    }))
})

const checkTimers = (state) => {
    const current = state.playerInfo[state.currentPlayer]
    if (current.checkCardsOn) {
        let unmatchedUps = []
        for (let card of state.cards) {
            if (card.side === 'up' && card.matched === false) {
                unmatchedUps.push(card)
            }
        }
        let matched = unmatchedUps[0].groupId
        for (let card of unmatchedUps) {
            if (card.groupId !== matched) {
                matched = false
            }
        }
        matched = !(matched === false)
        const setCheck = (diff) => (pInfo, index) => ({
            ...pInfo,
            points: index === state.currentPlayer ? pInfo.points + diff : pInfo.points,
            checkCardsOn: false,
        })
        const setCards = (extra, cards) =>
            cards.map((c) =>
                (c.side === 'up' && c.matched === false)
                ? { ...c, ...extra }
                : c
            )

        if (matched) {
            return {
                ...state,
                cards: setCards({ side: 'up', matched: true, lastMatched: state.generalTimer + 2 }, state.cards),
                playerInfo: state.playerInfo.map(setCheck(1)),
            }
        } else if (current.checkCardsOn <= current.timer) {
            return {
                ...state,
                cards: setCards({ side: 'down', matched: false }, state.cards),
                playerInfo: state.playerInfo.map(setCheck(0)),
                currentPlayer: (state.currentPlayer + 1) % state.playerInfo.length,
            }
        }
    }
    return state
}

const checkWin = (state) => {
    if (state.type === 'playing') {
        for (let card of state.cards) {
            if (!card.matched) {
                return state
            }
        }
        return {
            type: 'won',
            game: state
        }
    }
    return state
}

const advanceClock = (state) => {
    const newState = {
        ...state,
        generalTimer: state.generalTimer + 1,
        playerInfo: [...state.playerInfo]
        // shallow clone of playerInfo, such that the state ends up being immutable
    }
    newState.playerInfo[state.currentPlayer].timer++
    return checkWin(removeGreenTags(checkTimers(newState)))
}



export default (state = defGameState, action) => {
    switch (action.type) {
        case 'NEW_GAME':
            return newGame(action.playerCount, action.seed)
        case 'FLIP_CARD':
            if (state.type === 'playing') {
                return flipCard(state, action.cardId)
            } else {
                console.error('FLIP_CARD dispatched when there is no game running, ignoring...')
                return state
            }
        case 'CLOCK_TICK':
            if (state.type === 'playing') {
                return advanceClock(state)
            } else {
                return state
            }
        case 'STOP_GAME':
        case 'BACK_MENU':
            return {
                type: 'menu',
            }
        default:
            return state
    }
}
