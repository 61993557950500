import * as React from 'react'

export default class ResetIdle extends React.Component {

    constructor (props) {
      super(props)
      this.timer = null
    }

    restartTimer = () => {
      console.warn('restarting')
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(this.sendIdle, this.props.idleTime * 1000)
    }


    componentDidMount () {
        this.restartTimer()
      }
  
    componentDidUpdate (oldProps) {
        if (oldProps.idleTime !== this.props.idleTime) {
            this.restartTimer()
        }
    }

    componentWillUnmount () {
        if (this.timer) {
            clearTimeout(this.timer)
        }
    }


    sendIdle = () => {
        this.props.actions.backMenu()
        this.props.actions.setLanguage()
    }
  

    render = () => {
        return (
            <div onClick={() => this.restartTimer()} onTouchMove={() => this.restartTimer()} onKeyDown={() => this.restartTimer()}>
                {this.props.children}
            </div>
        )
      
    }

}