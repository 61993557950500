import * as React from 'react'

import RunningGame from './RunningGame'
import './styles.css'

const useStateWithLocalStorage = (localStorageKey, def) => {
    const [value, setValue] = React.useState(
      JSON.parse(localStorage.getItem(localStorageKey) || 'false') || def
    )
  
    React.useEffect(() => {
      localStorage.setItem(localStorageKey, JSON.stringify(value))
    }, [value])
  
    return [value, setValue]
}
  
const formatTime = (time) => {
    let date = new Date(null);
    date.setSeconds(time);
    return date.toISOString().substr(14, 5);
}

const groupByFn = (fn, xs) => {
    if (xs.length <= 1) return [xs]
    let ret = []
    let acum = [xs[0]]
    for (let i=1; i<xs.length; i++) {
        if (fn(xs[i-1], xs[i]) !== 0) {
            ret.push(acum)
            acum = []
        }
        acum.push(xs[i])
    }
    if (acum.length > 0) {
        ret.push(acum)
    }
    console.warn(ret)
    return ret
}

/*const joinLosers = (xs) => {
    let ret = []
    for (let i=1; i<xs.length; i++) {
        ret.concat(xs[i])
    }
    return ret
}*/

const WonModal = ({ t, game, backMenu }) => {
    const [ highScore, setHighScore ] = useStateWithLocalStorage('highScores', null)
    let hs = null
    const numberPlayers = game.playerInfo.length
    let winningPoints = 0
    for (let i = 0; i < numberPlayers; i++) {
        if (game.playerInfo[i].points >= winningPoints) winningPoints = game.playerInfo[i].points
    }

    const comparePlayers = ([, p1], [, p2]) => {
        if (p1.points === p2.points) {
            return p1.timer - p2.timer
        }
        return p2.points - p1.points
    }
    const players = game.playerInfo.map((pI, ix) => [ix, pI]).sort(comparePlayers)
    const playersGrouped = groupByFn(comparePlayers, players)
    const winners = playersGrouped[0]
    //const losers = joinLosers(playersGrouped)
    if (numberPlayers === 1) {
        const winner = winners[0]
        console.warn(winners, winner[0])
        if (winner) {
            // if it's a single player game, we'll handle high scores
            hs = (<p className={'new highscore'}>{t('new-highscore')}: {formatTime(winner[1].timer)}!</p>)
            if (!highScore) {
                setHighScore({ timer: winner[1].timer })
            } else {
                if (highScore.timer < winner[1].timer) {
                    hs = (<p className={'highscore'}>{t('current-highscore')}: {formatTime(highScore.timer)}!</p>)
                } else {
                    if (highScore.timer > winner[1].timer) {
                        setHighScore({ timer: winner[1].timer })
                    }
                }
            }
        }
    }
    const winnersText =
        winners.length === 1
        ? t('name-player' + (winners[0][0] + 1))
        : t('tied-game')
    // player.moves
    // game.currentPlayer is the player that won (0 for first, 1 for second)
    // by default, in single-player there is only one player but the whole logic works as if there were multiple
    return (
        <React.Fragment>
            <div className='won-modal'>
                <RunningGame t={t} disabled={true} game={game} />
            </div>
            <div className='won-stats'>
                <h1>{t('game-end')}</h1>
                <p>{t('winner')}: {winnersText}</p>
                {hs}
                <div className={'players-container'}>
                {players.map(p => {
                    const formatedTime = formatTime(p[1].timer)
                    return (
                        <React.Fragment>
                            <div>
                                <p>{t('name-player' + (p[0] + 1))}</p>
                                <p>{t('moves')}: {p[1].moves}</p>
                                <p>{t('time')}: {formatedTime}</p>
                            </div>
                        </React.Fragment>
                    )
                })}
                </div>
                <button onClick={backMenu}>
                    {t('back-menu')}
                </button>
            </div>
        </React.Fragment>
    )
}

export default WonModal
