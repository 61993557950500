import * as React from 'react'

import GameSidebar from './GameSidebar'
import CardGrid from './CardGrid'
import PlayerBar from './PlayerBar'
//import HelpBox from './HelpBox'
import config from '../config'

class RunningGame extends React.Component {

    setupTimer = () => {
        if (!(this.props.disabled === true)) {
            if (this.timer) {
                clearInterval(this.timer)
            }
            this.timer = setInterval(this.clockTick, 1000)
        }
    }

    componentDidMount () {
        this.setupTimer()
    }

    componentWillUnmount () {
        if (this.timer) {
            clearInterval(this.timer)
        }
    }

    componentDidUpdate (oldProps) {        
        if (oldProps.game.currentPlayer !== this.props.game.currentPlayer) {
            this.setupTimer()
        }
        if (oldProps.disabled !== this.props.disabled) {
            if (this.props.disabled === true) {
                if (this.timer) {
                    clearInterval(this.timer)
                    this.timer = null
                } else {
                    this.setupTimer()
                }
            }
        }
    }

    clockTick = () => {
        this.props.clockTick()
    }

    render () {
        const { t, game, disabled } = this.props
        if (!game) {
            return null
        }
        return (
            <div className='game-background' style={{ backgroundImage: `url('${config.gameBackground}')` }}>
                <GameSidebar t={t} disabled={disabled === true} game={game} stopGame={this.props.stopGame} />
                <CardGrid t={t} disabled={disabled === true} game={game} flipCard={this.props.flipCard} />
                <PlayerBar t={t} disabled={disabled === true} game={game} />
                {/*<HelpBox t={t} invisibleBoxClass='help-game-box-invisible' boxClass='help-game-box' buttonClass='help-game-button' />*/}
            </div>
        )
    }
}

export default RunningGame