
export const newGame = (playerCount = 1) => ({
        type: 'NEW_GAME',
        playerCount,
        seed: String(Math.random()) + String(Math.random()),
})

export const stopGame = () => ({
        type: 'STOP_GAME',
})

export const backMenu = () => ({
        type: 'BACK_MENU',
})

// there should be no external clock on the game
// and every timeout should be in terms of these (ideally)
export const clockTick = () => ({
        type: 'CLOCK_TICK',
})

export const flipCard = (cardId) => ({
        type: 'FLIP_CARD',
        cardId: cardId,
})

export default {
    newGame,
    stopGame,
    backMenu,
    clockTick,
    flipCard,
}