
export default {
    idleTime: 120,
    defaultLang: "en",
    menuBackground: require('./assets/iz19_Memory_Menu_1920x1080.png'),
    gameBackground: require('./assets/iz19_Memory_Hintergrundmotiv_1920x1080.png'),
    cardBackground: require('./assets/Rückseite_60-Years Logo.png'),
    defaultCards: [
        {
            id: '60-a',
            group: '60',
            image: require('./assets/01.png'),
        },
        {
            id: '60-b',
            group: '60',
            image: require('./assets/01.png'),
        },
        {
            id: '70-a',
            group: '70',
            image: require('./assets/02.png'),
        },
        {
            id: '70-b',
            group: '70',
            image: require('./assets/02.png'),
        },
        {
            id: '80-a',
            group: '80',
            image: require('./assets/03.png'),
        },
        {
            id: '80-b',
            group: '80',
            image: require('./assets/03.png'),
        },
        {
            id: '90-a',
            group: '90',
            image: require('./assets/04.png'),
        },
        {
            id: '90-b',
            group: '90',
            image: require('./assets/04.png'),
        },
        {
            id: '00-a',
            group: '00',
            image: require('./assets/05.png'),
        },
        {
            id: '00-b',
            group: '00',
            image: require('./assets/05.png'),
        },
        {
            id: '10-a',
            group: '10',
            image: require('./assets/06.png'),
        },
        {
            id: '10-b',
            group: '10',
            image: require('./assets/06.png'),
        },
        {
            id: '11-a',
            group: '11',
            image: require('./assets/07.png'),
        },
        {
            id: '11-b',
            group: '11',
            image: require('./assets/07.png'),
        },
        {
            id: '12-a',
            group: '12',
            image: require('./assets/08.png'),
        },
        {
            id: '12-b',
            group: '12',
            image: require('./assets/08.png'),
        }
    ]
}