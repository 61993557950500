import * as React from 'react'

import './styles.css'

const PlayerBar = ({ t, game, disabled }) => (
    game.playerInfo.length > 1 ? (
        <div className='player-bar'>
            <div className={'player-1' + ((game.currentPlayer === 0) ? ' active' : '')}>
            {t('name-player1')}: {game.playerInfo[0] ? game.playerInfo[0].points : 0}
            </div>
            <div className={'player-2' + ((game.currentPlayer === 1) ? ' active' : '')}>
            {t('name-player2')}: {game.playerInfo[1] ? game.playerInfo[1].points : 0}
            </div>
        </div>
    ) : null
)

export default PlayerBar