
import * as React from 'react'

import './styles.css'

const GameSidebar = ({ t, game, disabled, stopGame }) => {
    let date = new Date(null);
    date.setSeconds(game.generalTimer);
    let formatedTime = date.toISOString().substr(14, 5);

    return (
    <div className='game-sidebar'>
        <p className='sidebar-bubble'>
            {formatedTime}<br />
            {t('time')}
        </p>
        <br />
        <p className='sidebar-bubble'>
            {game.playerInfo[game.currentPlayer].moves}<br />
            {t('moves')}
        </p>
        <br />
        <button onClick={disabled ? undefined : stopGame}>
            {t('stop-game')}
        </button>
    </div>
    )
}


export default GameSidebar