import * as React from 'react'

import './styles.css'

const Int$Card = ({ id, groupId, imageUp, imageDown, side, lastMatched, containerStyle, onClick }) => (
    <div className={`card-container`} style={containerStyle}>
            <div className={"flip-card " + ((side === 'up') ? '' : 'active')} onClick={onClick}>
                <div className="flip-card-inner">
                    <div className="flip-card-front">
                        <div className={`card-image ${lastMatched ? 'matched' : ''}`} style={{ backgroundImage: `url('${imageUp}')` }}></div>
                    </div>
                    <div className="flip-card-back">
                        {/* fixme: this sometimes breaks images ...*/}
                        <div className='card-image' style={{ backgroundImage: `url('${imageDown}')` }}></div>
                    </div>
                </div>
            </div>
    </div>
)



const Card = Int$Card

export default Card