import * as React from 'react'

import Card from './Card'

import './styles.css'


const CardGrid = ({ game, flipCard, disabled }) => (
    <div className='card-grid'>
        {game.cards.map((card, index) => {
            const flip = (disabled || card.side === 'up') ? () => {} : () => flipCard(card.id)
            return (
                <Card key={index} {...card} onClick={flip} />
            )
        })}
    </div>
)


export default CardGrid