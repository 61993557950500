import * as React from 'react'

import ResetIdle from './ResetIdle'
import config from '../config'

//import Menu from './Menu'
import RunningGame from './RunningGame'
import WonModal from './WonModal'

import { translate } from '../utils/language'

const testWonGame = {
    "type": "playing",
    "currentPlayer": 1,
    "generalTimer": 60,
    "playerInfo": [{
        "checkCardsOn": false,
        "totalmoves": 10,
        "moves": 5,
        "points": 6,
        "timer": 40
    }, {
        "checkCardsOn": false,
        "totalmoves": 20,
        "moves": 10,
        "points": 6,
        "timer": 40
    }],
    "cards": [{
        "id": "80-b",
        "groupId": "80",
        "imageUp": "/static/media/80er Jahre_Mauerfall2.3bf312b2.png",
        "imageDown": "/static/media/Rückseite_60-Years Logo.11ea4dbf.png",
        "side": "up",
        "matched": true,
        "lastMatched": false
    }, {
        "id": "90-b",
        "groupId": "90",
        "imageUp": "/static/media/90er-Jahre-Tom-Vac.63562b19.png",
        "imageDown": "/static/media/Rückseite_60-Years Logo.11ea4dbf.png",
        "side": "up",
        "matched": true,
        "lastMatched": false
    }, {
        "id": "90-a",
        "groupId": "90",
        "imageUp": "/static/media/90er Jahre_Google.37364266.png",
        "imageDown": "/static/media/Rückseite_60-Years Logo.11ea4dbf.png",
        "side": "up",
        "matched": true,
        "lastMatched": false
    }, {
        "id": "00-a",
        "groupId": "00",
        "imageUp": "/static/media/00er Jahre_Smartphone.3caee76b.png",
        "imageDown": "/static/media/Rückseite_60-Years Logo.11ea4dbf.png",
        "side": "up",
        "matched": true,
        "lastMatched": 62
    }, {
        "id": "60-a",
        "groupId": "60",
        "imageUp": "/static/media/60er-Jahre_Astronaut.0756e41c.png",
        "imageDown": "/static/media/Rückseite_60-Years Logo.11ea4dbf.png",
        "side": "up",
        "matched": true,
        "lastMatched": false
    }, {
        "id": "10-b",
        "groupId": "10",
        "imageUp": "/static/media/10er-Jahre-HAL-Wood.8190f5d4.png",
        "imageDown": "/static/media/Rückseite_60-Years Logo.11ea4dbf.png",
        "side": "up",
        "matched": true,
        "lastMatched": 60
    }, {
        "id": "80-a",
        "groupId": "80",
        "imageUp": "/static/media/80er-Jahre-Lignet-Roset-Sofa.5c791050.png",
        "imageDown": "/static/media/Rückseite_60-Years Logo.11ea4dbf.png",
        "side": "up",
        "matched": true,
        "lastMatched": false
    }, {
        "id": "70-a",
        "groupId": "70",
        "imageUp": "/static/media/70er Jahre_Hippie Frauen.2aec0e9b.png",
        "imageDown": "/static/media/Rückseite_60-Years Logo.11ea4dbf.png",
        "side": "up",
        "matched": true,
        "lastMatched": false
    }, {
        "id": "10-a",
        "groupId": "10",
        "imageUp": "/static/media/10er Jahre_3D Drucker.8a00b4ad.png",
        "imageDown": "/static/media/Rückseite_60-Years Logo.11ea4dbf.png",
        "side": "up",
        "matched": true,
        "lastMatched": 60
    }, {
        "id": "70-b",
        "groupId": "70",
        "imageUp": "/static/media/70er-Jahre-WiggleSideChair.b7a81d03.png",
        "imageDown": "/static/media/Rückseite_60-Years Logo.11ea4dbf.png",
        "side": "up",
        "matched": true,
        "lastMatched": false
    }, {
        "id": "60-b",
        "groupId": "60",
        "imageUp": "/static/media/60er-Jahre-Panton-Chair-Classic.b45fff34.png",
        "imageDown": "/static/media/Rückseite_60-Years Logo.11ea4dbf.png",
        "side": "up",
        "matched": true,
        "lastMatched": false
    }, {
        "id": "00-b",
        "groupId": "00",
        "imageUp": "/static/media/00er-Jahre-Vegetal.e470f6e4.png",
        "imageDown": "/static/media/Rückseite_60-Years Logo.11ea4dbf.png",
        "side": "up",
        "matched": true,
        "lastMatched": 62
    }]
}

const debug = false
const Game = ({ state, actions }) => {
    // force german for schwaigerhof
    const t = translate('de')
    if (debug === 'won') {
        return (<WonModal t={t} game={testWonGame} backMenu={actions.backMenu} />)
    }

    switch (state.game.type) {
        case 'menu':
            // in case of schwaigerhof we wan to skip the menu
            // return (<Menu t={t} setLanguage={actions.setLanguage} newGame={actions.newGame} />)
            actions.newGame(1)
            return null
        case 'playing':
            return (<RunningGame key='game' t={t} game={state.game} clockTick={actions.clockTick} flipCard={actions.flipCard} stopGame={actions.stopGame} />)
        case 'won':
            return (<WonModal t={t} game={state.game.game} backMenu={actions.backMenu} />)
        default:
            console.warn(`invalid game type: ${state.game.type}`)
            return null
    }
}

export default (props) => (
    <ResetIdle {...props} idleTime={config.idleTime}>
        <Game {...props} />
    </ResetIdle>
)
/*
    <div>
        Current language: {state.language.lang} <br />
        Change to <button onClick={() => actions.setLanguage('de')}>DE</button> <button onClick={() => actions.setLanguage('en')}>EN</button> <br />
    </div>
*/