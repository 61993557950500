import {
    setLanguage
} from './language'

import {
    newGame,
    stopGame,
    backMenu,
    clockTick,
    flipCard
} from './game'

export default {
    // language
    setLanguage,

    // game
    newGame,
    stopGame,
    backMenu,
    clockTick,
    flipCard,
}
